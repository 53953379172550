<template>
  <div>
    <h1 class="mb-4">
      Profile
    </h1>

    <alert :text="alertText" :type="alertType" class="mb-8"></alert>

    <v-row>
      <v-col cols="12" sm="8" md="6">
        <v-form v-model="formValid" class="multi-col-validation">
          <v-text-field
            v-model="name"
            :disabled="externalProvider"
            label="Name"
            class="mb-4"
            outlined
            dense
          ></v-text-field>

          <v-text-field
            v-model="surname"
            :disabled="externalProvider"
            label="Surname"
            class="mb-4"
            outlined
            dense
          ></v-text-field>

          <v-text-field
            v-model="email"
            :disabled="externalProvider"
            label="Email"
            class="mb-4"
            outlined
            dense
          ></v-text-field>

          <v-text-field
            v-model="phone_number"
            label="Phone"
            class="mb-4"
            outlined
            dense
            :rules="[formatPhone]"
          >
            <template v-slot:prepend-inner>
              <v-combobox
                v-model="phone_code"
                :items="countryList"
                class="phone_code"
                hide-details
                dense
              >
                <template v-slot:selection="{ item }">
                  {{ item.emoji }}
                </template>
                <template v-slot:item="{ item }">
                  {{ item.emoji }} {{ item.name }} +{{ item.phone }}
                </template>
              </v-combobox>
            </template>
          </v-text-field>

          <v-text-field
            v-model="company"
            label="Company"
            class="mb-4"
            outlined
            dense
          ></v-text-field>

          <v-text-field
            v-model="address_line_1"
            label="Address Line 1"
            class="mb-4"
            outlined
            dense
          ></v-text-field>

          <v-text-field
            v-model="address_line_2"
            label="Address Line 2"
            class="mb-4"
            outlined
            dense
          ></v-text-field>

          <v-text-field
            v-model="city"
            label="City"
            class="mb-4"
            outlined
            dense
          ></v-text-field>

          <v-text-field
            v-model="region"
            label="State"
            class="mb-4"
            outlined
            dense
          ></v-text-field>

          <v-text-field
            v-model="postal_code"
            label="ZIP Code"
            class="mb-4"
            outlined
            dense
          ></v-text-field>

          <v-combobox
            v-model="country_code"
            label="Country"
            class="mb-4"
            :items="countryList"
            item-text="name"
            outlined
            dense
          ></v-combobox>

          <div class="text-center">
            <v-btn
              color="primary"
              class="my-4 me-4"
              :loading="saving"
              :disabled="!formValid"
              rounded
              @click="updateUser"
            >
              Save Changes
            </v-btn>

            <v-btn
              color="secondary"
              class="my-4"
              type="reset"
              outlined
              rounded
              @click.prevent="setFormFields"
            >
              Reset
            </v-btn>
          </div>
        </v-form>
      </v-col>
      <v-col cols="12" sm="4" md="6" class="text-center">
        <v-divider class="mb-6 d-sm-none"></v-divider>
        <p class="mb-4 text-h6">
          Identity Provider
        </p>
        <p class="mb-6">
          <v-chip icon large class="px-8">
            <v-icon left size="25px" :color="$vuetify.theme.dark ? providerInfo.colorInDark : providerInfo.color">
              {{ providerInfo.icon }}
            </v-icon>
            {{ providerInfo.provider }}
          </v-chip>
        </p>
        <v-btn
          v-if="!externalProvider"
          color="secondary"
          class="mb-6"
          type="reset"
          outlined
          rounded
          small
          @click="$auth.loginWithRedirect({ connection: 'Username-Password-Authentication' })"
        >
          Reset Password
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { countries } from 'countries-list'
import providers from './providers'
import Alert from '@/components/Alert.vue'

export default {
  components: { Alert },
  data() {
    return {
      name: undefined,
      surname: undefined,
      email: undefined,
      email_verified: true,
      phone_number: undefined,
      phone_code: undefined,
      company: undefined,
      address_line_1: undefined,
      address_line_2: undefined,
      city: undefined,
      region: undefined,
      postal_code: undefined,
      country_code: undefined,
      country_name: undefined,
      formValid: undefined,
      saving: false,
      alertText: undefined,
      alertType: undefined,
    }
  },
  computed: {
    provider() {
      var idp = this.$auth.user.sub.split('|')[0]
      return idp
    },
    providerInfo() {
      var connection = this.provider === 'auth0' ? 'Username-Password-Authentication' : this.provider
      return providers.filter((provider) => {
        return provider.connection === connection
      })[0]
    },
    externalProvider() {
      return this.provider !== 'auth0'
    },
    countryList() {
      var list = Object.keys(countries).map(code => {
        return {
          code: code,
          name: countries[code].name,
          phone: countries[code].phone,
          emoji: countries[code].emoji,
        }
      }).sort((a, b) => {
        return a.name > b.name ? 1 : -1
      })
      list.splice(-1, 1)
      list.unshift(list[237])
      return list
    },
  },
  mounted() {
    this.setFormFields()
    this.formatPhone()

    if (!this.email_verified) {
      this.alertText = 'Your email is not confirmed. Please check your inbox.'
      this.alertType = 'warning'
    }
  },
  methods: {
    setFormFields() {
      if (this.$auth.isAuthenticated) {
        var user_metadata = this.$auth.user['https://namespace/user_metadata']
        var request_geoip = this.$auth.user['https://namespace/request_geoip']

        this.name = this.$auth.user.given_name
        this.surname = this.$auth.user.family_name
        this.email = this.$auth.user.email
        this.email_verified = this.$auth.user.email_verified
        this.phone_number = user_metadata.phone_number
        this.phone_code = this.countryList.filter((c) => { return c.phone === user_metadata.phone_code || c.code === request_geoip.country_code })[0]
        this.company = user_metadata.company
        this.address_line_1 = user_metadata.address_line_1
        this.address_line_2 = user_metadata.address_line_2
        this.city = user_metadata.city || request_geoip.city_name
        this.region = user_metadata.region || request_geoip.region
        this.postal_code = user_metadata.postal_code || request_geoip.postal_code
        this.country_code = this.countryList.filter((c) => { return c.code === user_metadata.country_code || c.code === request_geoip.country_code })[0]
        this.country_name = user_metadata.country_name || request_geoip.country_name
      }
    },
    setUserFields(user) {
      if (user) {
        var user_metadata = this.$auth.user['https://namespace/user_metadata']

        this.$auth.user.given_name = user.given_name
        this.$auth.user.family_name = user.family_name
        this.$auth.user.name = `${user.given_name} ${user.family_name}`
        this.$auth.user.email = user.email
        this.$auth.user.email_verified = user.email_verified
        user_metadata.phone_code = user.user_metadata.phone_code
        user_metadata.phone_number = user.user_metadata.phone_number
        user_metadata.company = user.user_metadata.company
        user_metadata.address_line_1 = user.user_metadata.address_line_1
        user_metadata.address_line_2 = user.user_metadata.address_line_2
        user_metadata.city = user.user_metadata.city
        user_metadata.region = user.user_metadata.region
        user_metadata.postal_code = user.user_metadata.postal_code
        user_metadata.country_code = user.user_metadata.country_code
        user_metadata.country_name = user.user_metadata.country_name
      }
    },
    async updateUser() {
      this.saving = true

      var user = {
        user_metadata: {
          phone_code: this.phone_code.phone,
          phone_number: this.phone_number,
          company: this.company,
          address_line_1: this.address_line_1,
          address_line_2: this.address_line_2,
          city: this.city,
          region: this.region,
          postal_code: this.postal_code,
          country_code: this.country_code.code,
          country_name: this.country_code.name,
        },
      }

      if (!this.externalProvider) {
        user.given_name = this.name
        user.family_name = this.surname
        user.name = `${this.name} ${this.surname}`
      }

      this.$auth.updateUser(user, (err, user) => {
        if (err) {
          this.alertText = err.message
          this.alertType = 'error'
          this.saving = false
        } else {
          this.alertText = 'Profile saved successfully.'
          this.alertType = 'success'
          this.setUserFields(user)
          this.setFormFields()
          this.saving = false
        }
        window.scrollTo(0, 0)
      })
    },
    formatPhone() {
      if (this.phone_number) {
        var x = this.phone_number.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
        this.phone_number = !x[2] ? x[1] : x[1] + ' ' + x[2] + (x[3] ? ' ' + x[3] : '')
      }
      return true
    },
  },
}
</script>

<style scoped>
.phone_code {
  margin: 0px;
}
.phone_code /deep/ .v-input__slot {
  margin: 0px;
}
.phone_code /deep/ .v-input__slot:before {
  border-style: none !important;
  content: none;
}
.phone_code /deep/ .v-input__slot:after {
  border-style: none !important;
  content: none;
}
.phone_code /deep/ .v-input__slot .v-select__selections {
  border-style: none !important;
  content: none;
  width: 25px !important;
  min-width: 25px !important;
  max-width: 25px !important;
  font-size: 20px;
}
.phone_code /deep/ .v-input__slot input {
  border-style: none !important;
  content: none;
  width: 0px !important;
  min-width: 0px !important;
  max-width: 0px !important;
}
.phone_code /deep/ .v-input__slot .v-input__append-inner {
  margin: 0px !important;
}
</style>
