// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiMicrosoft, mdiApple, mdiLinkedin, mdiEmail } from '@mdi/js'

const data = [
  {
    provider: 'Google',
    connection: 'google-oauth2',
    icon: mdiGoogle,
    color: '#db4437',
    colorInDark: '#db4437',
  },
  // {
  //   provider: 'Microsoft',
  //   connection: 'windowslive',
  //   icon: mdiMicrosoft,
  //   color: '#db4437',
  //   colorInDark: '#db4437',
  // },
  {
    provider: 'Facebook',
    connection: 'facebook',
    icon: mdiFacebook,
    color: '#4267b2',
    colorInDark: '#4267b2',
  },
  // {
  //   provider: 'Apple',
  //   connection: 'apple',
  //   icon: mdiApple,
  //   color: '#000000',
  //   colorInDark: '#ffffff',
  // },
  {
    provider: 'Twitter',
    connection: 'twitter',
    icon: mdiTwitter,
    color: '#1da1f2',
    colorInDark: '#1da1f2',
  },
  // {
  //   provider: 'LinkedIn',
  //   connection: 'linkedin',
  //   icon: mdiLinkedin,
  //   color: '#0073b1',
  //   colorInDark: '#0073b1',
  // },
  // {
  //   provider: 'GitHub',
  //   connection: 'github',
  //   icon: mdiGithub,
  //   color: '#272727',
  //   colorInDark: '#fff',
  // },
  {
    provider: 'Email',
    connection: 'Username-Password-Authentication',
    icon: mdiEmail,
    color: '#272727',
    colorInDark: '#fff',
  },
]

export default data
